import React from 'react';

import { Form, Select, InputNumber, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import SimpleService from './Common/SimpleService';

import InfoTooltip from '../../shared/Components/InfoTooltip';

const { Option } = Select;

export default function ZillowScraper() {
  const { t } = useTranslation();

  return (
    <SimpleService
      unitName='listing'
      title='Zillow Search Scraper'
      subTitle='Returns data from Zillow'
      serviceName='zillow_search_service'
      queriesPlaceholder='Jacksonville, FL&#10;https://www.zillow.com/jacksonville-fl/sold/?searchQueryState=...'
      learnMoreUrl='https://outscraper.com/zillow-scraper'
      tutorialLink='https://outscraper.com/real-estate-zillow-data-extraction-no-coding-scraper/'
      apiTag='Others/paths/~1zillow-search/get'
      maxLimit={1000}
      ExtraItems={({ updateTask, task }) => {
        const { main_filter = 'for_sale', skip = 0 } = task;

        return <>
          <Form.Item label={t('title.filtering', 'Filtering')}>
            <Select value={main_filter} style={{ width: 150 }} onChange={(v) => updateTask({ main_filter: v })}>
              <Option key='for_sale' value='for_sale'>For Sale</Option>
              <Option key='for_rent' value='for_rent'>For Rent</Option>
              <Option key='sold' value='sold'>Sold</Option>
            </Select>
          </Form.Item>

          <Form.Item label={t('title.Skip', 'Skip')}>
            <Space align='center'>
              <InputNumber
                min={0}
                max={410}
                step={41}
                value={skip}
                onChange={(v) => updateTask({ skip: Math.round(parseInt(v) / 41) * 41 || 0 })}
              />
              <InfoTooltip title={t('description.skip', 'Parameter specifies the number of items to skip. Commonly used in pagination')}/>
            </Space>
          </Form.Item>
        </>;
      }}
      supportedEnrichmentServices={['whitepages_addresses', 'fastbackgroundcheck_addresses']}
    />
  );
}
