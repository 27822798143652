import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Popover, Button, Space, notification } from 'antd';
import config from 'react-global-configuration';
import { useDispatch } from 'react-redux';

import DownloadableTaskResult from './DownloadableTaskResult';
import { GoogleSheets } from '../../assets/icons';
import { exportToSpreadsheet } from '../../redux/actions/tasksActions';
import { connectGoogleSheetsAndReturnCredentials } from '../../redux/actions/profileActions';
import { isGoogleIntegrationExists } from '../../utils/utils';

const { Text } = Typography;
const taskExpiredAfter = config.get('taskExpiredAfter');
const supportedExtensions = new Set(['csv', 'xlsx', 'json']);

export default function TaskResults({ showEmptyTaskInfo, value = [], taskId, created, size = 'small', showInfo = true, type = 'default', extension, doRefresh }) {
  const expired = Date.now() - Date.parse(created) > taskExpiredAfter * 60 * 60 * 24 * 1000;
  const valueArray = value ? value : [];
  const downloadableResults = valueArray.length > 1 ? valueArray.filter(r => r.file_url) : valueArray;
  const totalUsage = valueArray.reduce((a, b) => a + b.quantity, 0);
  const isSupportedExtension = supportedExtensions.has(extension);

  const dispatch = useDispatch();

  function onShowEmptyTaskInfo() {
    showEmptyTaskInfo(downloadableResults.length > 0 ? downloadableResults[0] : {});
  }

  async function onOpenSpreadsheet() {
    try {
      const credentials = isGoogleIntegrationExists() ? {} : await connectGoogleSheetsAndReturnCredentials();
      const newSpreadsheetUrl = await dispatch(exportToSpreadsheet(taskId, credentials.access_token, credentials.refresh_token));

      if (newSpreadsheetUrl) {
        notification.success({
          message: <a href={newSpreadsheetUrl} target='_blank' rel='noopener noreferrer'>Open on Google Sheets</a>,
          duration: 30, style: { width: 600 }
        });
        window.open(newSpreadsheetUrl, '_blank', 'noopener,noreferrer');
      } else {
        console.error('Failed to upload file:', newSpreadsheetUrl);
      }
    } catch (error) {
      console.error('Error exporting file:', error);
    }
  }

  return <Space>
    {expired ?
      <Text type='secondary'>Expired</Text> :
      <Space align='baseline'>
        <Popover
          content={showInfo ? <>
            {valueArray.map((result, i) => <div key={`result-${i}`}>
              <strong>{result.product_name}</strong> - usage: {result.quantity}{result.ignored ? `, ignored: ${result.ignored} (ignored by exact match filter, other filters, duplicates, etc.)` : ''}
            </div>)
            }
            {!totalUsage && showEmptyTaskInfo && <>
              <br/>
              <>Task returned no results. <a onClick={onShowEmptyTaskInfo}>Why did it happen?</a></>
            </>}
          </> : null}
        >
          {downloadableResults.map((result, i) => (
            <div key={`result-${i}`}>
              <DownloadableTaskResult key={`btn-${i}`} value={result} size={size} type={type} />
              {isSupportedExtension && !!result.quantity && result.quantity <= 10000 && <Button
                key={`btn-spreadsheet-${i}`}
                type='link'
                title='Open table in Google Sheets'
                disabled={result.quantity === 0}
                icon={<GoogleSheets />}
                href={result.spreadsheet_url || undefined}
                target={result.spreadsheet_url ? '_blank' : undefined}
                onClick={result.spreadsheet_url ? undefined : onOpenSpreadsheet}
              />}
            </div>
          ))}
        </Popover>
      </Space>
    }
  </Space>;
}

TaskResults.propTypes = {
  showEmptyTaskInfo: PropTypes.func,
  value: PropTypes.array,
  taskId: PropTypes.string,
  created: PropTypes.string,
  size: PropTypes.string,
  showInfo: PropTypes.bool,
  type: PropTypes.string,
  extension: PropTypes.string,
  doRefresh: PropTypes.func,
};
