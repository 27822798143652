import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'query-string';
import { Table, Tag, Empty, Drawer, Tour, Space, Button } from 'antd';
import { SearchOutlined, RedoOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';
import Moment from 'react-moment';
import ReactJson from 'react-json-view';
import config from 'react-global-configuration';
import { useNavigate } from 'react-router-dom';

import { trimTaskMetadata, getTags, getServiceUrl } from './../../utils/utils';
import { setTemplateTask } from './../../utils/defaultProps';
import { fetchTasks, rerunTasks, removeTasks, scheduleTask } from '../../redux/actions/tasksActions';
import SearchFilterDropdown from './SearchFilterDropdown';
import TaskProgress from './TaskProgress';
import TaskResults from './TaskResults';
import TaskActions from './TaskActions';
import ScheduleModal from './ScheduleModal';

const emptyTaskCommonProblems = {
  'Google Maps Data': <>
    1. Try to run your task without the &quot;Exact Match&quot; parameter.<br/><br/>
    2. Check<a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/google-maps-data-scraper-filters/'> filters</a>. Make sure it correlates with the real data. Start a small task without filters to see the values it returns. Adjust your filters accordingly.<br/><br/>
    3. If using plain queries, make sure you are using countries inside queries. Make sure the country inside your queries correlates with the &quot;Country&quot; parameter.<br/><br/>
    4. Make sure the queries you are using can be opened on <a target='_blank' rel='noreferrer' href='https://www.google.com/maps/search/bars,+ny,+usa'>Google Maps</a>.<br/><br/>
  </>,
  'Google Maps Reviews': <>
    1. Make sure that the place actually has reviews in Google Maps.<br/><br/>
    2. If using links as queries, make sure that this is a <a target='_blank' rel='noreferrer' href='https://goo.gl/maps/mbe1ztdcoBtYwvwn7'>Google Maps link</a> and not a link to Google Search.<br/><br/>
    3. Check your task limits and cutoff parameters (date, rating).<br/><br/>
  </>
};

export default function TasksTable({ selected = false, refresh = 0, doRefresh }) {
  const navigate = useNavigate();
  const tasks = useSelector(state => state.tasksReducer.tasks);
  const hasMore = useSelector(state => state.tasksReducer.hasMoreTasks);
  const loading = useSelector(state => state.tasksReducer.loading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const searchInput = useRef();
  const searchIcon = useRef();

  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [query, setQuery] = useState('');
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastIds, setLastIds] = useState(['']);

  const [showEmptyTaskInfo, setShowEmptyTaskInfo] = useState(false);
  const [emptyTaskBody, setEmptyTaskBody] = useState(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const currentLastId = (tasks.length > 0) ? tasks[tasks.length-1].id : '';

  const [isTourVisible, setIsTourVisible] = useState(false);

  const isSelected = selectedRowKeys.length > 0;

  const tasksColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: '190px',
    },
    {
      title: t('title.created', 'Created'),
      dataIndex: 'created',
      key: 'created',
      width: '155px',
      render: created => created ? <Moment utc local format='YYYY/MM/DD HH:mm'>{created}</Moment> : '',
    },
    {
      title: t('title.status', 'Status'),
      dataIndex: 'progress',
      key: 'progress',
      width: '100px',
      render: (progress, record) => <TaskProgress task={record} />
    },
    {
      title: t('title.results', 'Results'),
      dataIndex: 'results',
      key: 'results',
      render: (results, record) => <TaskResults value={results} taskId={record.id} created={record.created} showEmptyTaskInfo={onShowEmptyTaskInfo} extension={record.metadata.settings.output_extension} doRefresh={doRefresh} />
    },
    {
      title: t('title.tags', 'Tags'),
      dataIndex: 'metadata',
      key: 'tags',
      render: (metadata) => <>{getTags(metadata).map(tag => (<Tag key={tag}>{tag}</Tag>))}</>,
      // eslint-disable-next-line react/prop-types
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilterDropdown
          searchInput={searchInput}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          onSearch={onSearch}
          onSearchReset={onSearchReset}
          clearFilters={clearFilters}
          confirm={confirm}
        />
      ),
      filterIcon: filtered => <SearchOutlined ref={searchIcon} style={{ color: filtered ? '#1890ff' : undefined, fontSize: 22 }} />,
      onOpenChange: visible => { if (visible) selectSearch(); }
    },
    {
      title: t('title.actions', 'Actions'),
      dataIndex: 'id',
      key: 'actions',
      fixed: 'right',
      width: '255px',
      render: (id, record) => <TaskActions
        task={record}
        onRerun={onRerunTask}
        onRemove={onRemoveTask}
        onRecreate={onRecreateTask}
        onSchedule={() => {
          setIsScheduleModalVisible(!isScheduleModalVisible);
          setEditingTaskId(id);
        }}
      />
    },
  ];

  const expiredAfter = config.get('taskExpiredAfterSoft');

  const handleRerunTasks = () => {
    onRerunTasks(selectedRowKeys);
    setSelectedRowKeys([]);
  };

  const handleRemoveTasks = () => {
    onRemoveTasks(selectedRowKeys);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (tasks && tasks.length > 0 && !localStorage.getItem('tasksTutorial')) setIsTourVisible(true);
  }, [tasks]);

  useEffect(() => {
    document.title = t('title.tasks', 'Tasks') + ' | ' + config.get('company');

    if (selected) {
      dispatch(fetchTasks('', currentPageSize, query));

      const intervalId = setInterval(() => {
        let innerPage = 0;
        let innerCurrentPageSize = 0;
        let innerQuery = '';

        setCurrentPage((currentPage) => {innerPage = currentPage; return currentPage;});
        setCurrentPageSize((currentPageSize) => {innerCurrentPageSize = currentPageSize; return currentPageSize;});
        setQuery((query) => {innerQuery = query; return query;});

        if (innerPage === 1 && !innerQuery && innerCurrentPageSize == 10) {
          dispatch(fetchTasks('', innerCurrentPageSize, innerQuery));
        }
      }, 1000 * 60 * 10);

      return () => clearInterval(intervalId);
    }
  }, [selected, refresh]);

  function onFinishTour() {
    setIsTourVisible(false);
    localStorage.setItem('tasksTutorial', '1');
  }

  function selectSearch() {
    setTimeout(() => searchInput.current.select());
  }

  function onPagination({ current, pageSize }) {
    if (pageSize !== currentPageSize || current !== currentPage) {
      if (pageSize !== currentPageSize) {
        setLastIds(['']);
      }

      if (current > lastIds.length) {
        setLastIds([...lastIds, currentLastId]);
        dispatch(fetchTasks(currentLastId, pageSize, query));
      } else {
        dispatch(fetchTasks(lastIds[current - 1], pageSize, query));
      }

      setCurrentPage(current);
      setCurrentPageSize(pageSize);
    }
  }

  function onRecreateTask(metadata) {
    const { service_name } = metadata;
    const serviceUrl =  getServiceUrl(service_name);

    if (!metadata.tags) {
      delete metadata.tags;
    }
    delete metadata.notify_on_finish;
    delete metadata.customer_email;
    delete metadata.queries_amount;
    delete metadata.user_id;
    delete metadata.id;

    if (serviceUrl) {
      setTemplateTask(metadata);
      navigate(`/${serviceUrl}`);
    }
  }

  function onRerunTask(taskId) {
    onRerunTasks([taskId]);
  }

  function onRerunTasks(taskIds) {
    dispatch(rerunTasks(taskIds));
  }

  async function onRemoveTask(taskId) {
    await onRemoveTasks([taskId]);
  }

  async function onRemoveTasks(taskIds) {
    await dispatch(removeTasks(taskIds));

    dispatch(fetchTasks('', currentPageSize));
    setCurrentPage(1);
    setLastIds(['']);
  }

  function onSearch(value, confirm) {
    navigate({ search: qs.stringify({ query: value }) });

    dispatch(fetchTasks('', currentPageSize, value));

    setQuery(value);
    setCurrentPage(1);
    setLastIds(['']);

    confirm();
  }

  function onSearchReset(clearFilters) {
    navigate({ search: qs.stringify({ }) });

    dispatch(fetchTasks('', currentPageSize));

    setQuery('');
    setCurrentPage(1);
    setLastIds(['']);

    clearFilters();
  }

  function onScheduleTask(crontab) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(scheduleTask(editingTaskId, crontab, timezone));

    setEditingTaskId(null);
    setIsScheduleModalVisible(false);
  }

  function onShowEmptyTaskInfo(result) {
    const { product_name } = result;
    setShowEmptyTaskInfo(true);
    setEmptyTaskBody(emptyTaskCommonProblems[product_name]);
  }

  function onHideEmptyTaskInfo() {
    setShowEmptyTaskInfo(false);
  }

  const filterTasksByStatus = (status) => {
    return tasks.filter(task => task.status === status).map(task => task.id);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    selections: [
      {
        key: 'all',
        text: 'All',
        onSelect: () => {
          setSelectedRowKeys(tasks.map(task => task.id));
        },
      },
      {
        key: 'none',
        text: 'None',
        onSelect: () => {
          setSelectedRowKeys([]);
        },
      },
      {
        key: 'started',
        text: 'In progress',
        onSelect: () => {
          setSelectedRowKeys(filterTasksByStatus('STARTED'));
        },
      },
      {
        key: 'success',
        text: 'Completed',
        onSelect: () => {
          setSelectedRowKeys(filterTasksByStatus('SUCCESS'));
        },
      }
    ]
  };

  return <>
    <Space size={24} className='mb-16'>
      <span className='btn'><Trans
        i18nKey='title.selected'>Selected</Trans>: {selectedRowKeys.length}</span>
      <Space size={8}>
        <Button onClick={handleRerunTasks} disabled={!isSelected} icon={<RedoOutlined/>}>
          <Trans i18nKey='action.restart'>Restart</Trans>
        </Button>
        <Button onClick={handleRemoveTasks} disabled={!isSelected} icon={<CloseCircleOutlined/>}>
          <Trans i18nKey='action.remove'>Remove</Trans>
        </Button>
      </Space>
    </Space>
    <Table
      rowKey='id'
      scroll={{ x: 1300 }}
      loading={loading}
      columns={tasksColumns}
      dataSource={tasks}
      rowSelection={rowSelection}
      expandable={{ expandedRowRender: task => <ReactJson src={trimTaskMetadata(task.metadata)} name='metadata'/> }}
      pagination={{
        pageSize: currentPageSize,
        current: currentPage,
        total: lastIds.length * currentPageSize + hasMore - (currentPage < lastIds.length),
        pageSizeOptions: [10, 20],
        showSizeChanger: true,
      }}
      onChange={onPagination}
      locale={{ emptyText: <Empty description={t('description.tasksEmpty', 'You have no tasks yet')} /> }}
    />
    <p className='help-p1'>
      * <Trans i18nKey='description.tasks'>Results will be available for {{ expiredAfter }} days to download</Trans>
    </p>

    <ScheduleModal open={isScheduleModalVisible} onSave={onScheduleTask} onCancel={() => setIsScheduleModalVisible(false)} />

    <Drawer
      closable={false}
      title={t('description.whyNoResutls', 'Why Does my Task Return Empty Results?')}
      open={showEmptyTaskInfo}
      onClose={onHideEmptyTaskInfo}
    >
      {emptyTaskBody}<br/>
      If none of the above help, please consider checking <a target='_blank' rel='noreferrer' href='https://outscraper.com/category/tutorials/'>tutorials</a> or contact support (Left Panel -&gt; Help -&gt; Support).
    </Drawer>

    <Tour
      open={isTourVisible}
      onClose={onFinishTour}
      steps={
        [{
          target: () => document.querySelector('.copy-task'),
          title: t('tour.copyTask', 'Effortlessly create new tasks by using existing ones as templates.'),
        },
        {
          target: () => document.querySelector('.schedule-task'),
          title: t('tour.scheduleTask', 'Schedule and consistently execute tasks on a regular basis.'),
        },
        {
          target: () => document.querySelector('.share-task'),
          title: t('tour.shareTask', 'Share task links with your team for enhanced collaboration.'),
        }]
      }
    />
  </>;
}

TasksTable.propTypes = {
  selected: PropTypes.bool,
  refresh: PropTypes.number,
  doRefresh: PropTypes.func,
};
